import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Stack,
  CircularProgress,
  Backdrop,
  CssBaseline,
  Grid,
  Paper,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactSpeedometer from 'react-d3-speedometer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { initGA, logPageView, logEvent, logCodigo, logErro } from './analytics'; // Importe as funções do analytics.js

const appTheme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    text: {
      primary: '#000000',
    },
  },
});

// Estilos globais
const subtituloEstilo = {
  background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: '32px',
  marginBottom: '32px',
};

function App() {
  const [codigo, setCodigo] = useState('');
  const [resultado, setResultado] = useState(null);
  const [loading, setLoading] = useState(false);

  // Estilos responsivos para o título
  const tituloEstilo = {
    background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: {
      xs: '50px',
      sm: '60px',
      md: '90px',
    },
  };

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    if (resultado && resultado.codigo) {
      document.title = `Devo Investir - ${resultado.codigo}`;
    } else {
      document.title = 'Devo Investir';
    }
  }, [resultado]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia o loading
    try {
      const response = await axios.get(
        `/api/detalhe?codigo=${codigo}`
      );
      logEvent('Código pesquisado', '', codigo.toUpperCase());
      logCodigo(codigo.toUpperCase());
      setResultado(response.data);

      // Registrar o evento de pesquisa no Google Analytics
      
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      logErro(codigo.toUpperCase())
      setResultado({ error: 'Não foi possível obter os dados da ação.' });
    }
    setLoading(false); // Finaliza o loading
  };

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container
        maxWidth="lg"
        style={{
          minHeight: '100vh',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={4} // Espaçamento de 32px entre o título e o TextField
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Typography variant="h1" sx={{ ...tituloEstilo, width: '100%' }}>
              Qual ação você quer analisar?
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Stack
                direction="column"
                spacing={4} // Espaçamento de 32px entre o TextField e o Button
                alignItems="center"
              >
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="Ex: PETR4"
                  value={codigo}
                  onChange={(e) => setCodigo(e.target.value.trim().toUpperCase())}
                  InputProps={{
                    style: { fontSize: 24, color: '#000000' },
                    disableUnderline: false,
                  }}
                  sx={{
                    width: 'calc(100% - 64px)',
                    marginLeft: '32px',
                    marginRight: '32px',
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    fontSize: 18,
                    borderRadius: 32,
                    background:
                      'linear-gradient(to right, #30CFD0 0%, #330867 100%)',
                    color: '#ffffff',
                    width: 'calc(100% - 64px)',
                    maxWidth: '240px',
                    marginLeft: '32px',
                    marginRight: '32px',
                  }}
                  disabled={loading}
                >
                  <span style={{ fontWeight: 'bold' }}>Devo Investir?</span>
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
        {resultado && (
          <div
            style={{
              marginTop: '40px',
              color: '#000000',
              width: '100%',
              maxWidth: '800px',
            }}
          >
            <Resultado data={resultado} />
          </div>
        )}
      </Container>
    </ThemeProvider>
  );
}

function formatNumber(num) {
  if (num === null || num === undefined) return 'N/A';
  return parseFloat(num).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function formatDate(dateString) {
  if (!dateString) return 'N/A';
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
}

const Resultado = ({ data }) => {
  if (data.error) {
    return (
      <Typography color="error" style={{ marginTop: '20px' }}>
        {data.error}
      </Typography>
    );
  }

  // Desestruturar todos os campos do JSON
  const {
    codigo,
    preco,
    lpa,
    vpa,
    pl,
    pvp,
    cres_rec_5a,
    div_yield,
    div_liquida,
    patrim_liquido,
    roe,
    bazin,
    graham,
    ativ_circ,
    cap_giro,
    ebit_12,
    ebit_3,
    ev_ebit,
    ev_ebitda,
    lucro_12,
    lucro_3,
    marg_bruta,
    marg_ebit,
    marg_liquida,
    max_52,
    min_52,
    pativos,
    pebit,
    psr,
    quant_acoes,
    rec_liquida_12,
    rec_liquida_3,
    roic,
    setor,
    ultimo_balanco,
    valor_firma,
    valor_mercado,
  } = data;

  // Atualizar os critérios de investimento se necessário
  const criteria = [
    {
      description: 'P/L maior que 3 e menor que 10',
      passed: pl > 3 && pl < 10,
    },
    {
      description: 'Dividend Yield maior que 6%',
      passed: div_yield > 6,
    },
    {
      description: 'P/VP menor que 5',
      passed: pvp < 5,
    },
    {
      description: 'ROE maior que 15%',
      passed: roe > 15,
    },
    {
      description: 'Dívida Líquida / Patrimônio Líquido menor que 1',
      passed: div_liquida / patrim_liquido < 1,
    },
  ];

  const speedometerValue = criteria.filter((c) => c.passed).length;

  // Informações Complementares
  const infoComplementares = [
    { label: 'Cotação', value: `R$ ${formatNumber(preco)}` },
    { label: 'Setor', value: setor },
    { label: 'Número de Ações', value: formatNumber(quant_acoes) },
    { label: 'Último Balanço', value: formatDate(ultimo_balanco) },
    { label: 'Máxima em 52 semanas', value: `R$ ${formatNumber(max_52)}` },
    { label: 'Mínima em 52 semanas', value: `R$ ${formatNumber(min_52)}` },
  ];

  // Dados Fundamentalistas
  const infoFundamentalistas = [
    { label: 'LPA', value: formatNumber(lpa) },
    { label: 'VPA', value: formatNumber(vpa) },
    { label: 'P/L', value: formatNumber(pl) },
    { label: 'P/VP', value: formatNumber(pvp) },
    { label: 'P/Ativos', value: formatNumber(pativos) },
    { label: 'P/EBIT', value: formatNumber(pebit) },
    { label: 'PSR', value: formatNumber(psr) },
    { label: 'EV/EBIT', value: formatNumber(ev_ebit) },
    { label: 'EV/EBITDA', value: formatNumber(ev_ebitda) },
    { label: 'Crescimento Receita (5 anos)', value: `${formatNumber(cres_rec_5a)}%` },
    { label: 'Dividend Yield', value: `${formatNumber(div_yield)}%` },
    { label: 'Margem Bruta', value: `${formatNumber(marg_bruta)}%` },
    { label: 'Margem EBIT', value: `${formatNumber(marg_ebit)}%` },
    { label: 'Margem Líquida', value: `${formatNumber(marg_liquida)}%` },
    { label: 'Dívida Líquida', value: `R$ ${formatNumber(div_liquida)}` },
    { label: 'Patrimônio Líquido', value: `R$ ${formatNumber(patrim_liquido)}` },
    { label: 'ROE', value: `${formatNumber(roe)}%` },
    { label: 'ROIC', value: `${formatNumber(roic)}%` },
    { label: 'Ativo Circulante', value: `R$ ${formatNumber(ativ_circ)}` },
    { label: 'Capital de Giro', value: `R$ ${formatNumber(cap_giro)}` },
    { label: 'Valor de Mercado', value: `R$ ${formatNumber(valor_mercado)}` },
    { label: 'Valor da Firma', value: `R$ ${formatNumber(valor_firma)}` },
  ];

  // Demonstração de Resultado - Últimos 12 meses
  const infoDemonstrativo12Meses = [
    { label: 'Receita Líquida', value: `R$ ${formatNumber(rec_liquida_12)}` },
    { label: 'EBIT', value: `R$ ${formatNumber(ebit_12)}` },
    { label: 'Lucro Líquido', value: `R$ ${formatNumber(lucro_12)}` },
  ];

  // Demonstração de Resultado - Últimos 3 meses
  const infoDemonstrativo3Meses = [
    { label: 'Receita Líquida', value: `R$ ${formatNumber(rec_liquida_3)}` },
    { label: 'EBIT', value: `R$ ${formatNumber(ebit_3)}` },
    { label: 'Lucro Líquido', value: `R$ ${formatNumber(lucro_3)}` },
  ];

  return (
    <Box sx={{ textAlign: 'center' }}>
      {/* Informações Complementares */}
      <Typography variant="h5" color="text.primary" sx={{ marginTop: '20px', marginBottom: '20px'}}>
        Resultados para <strong>{codigo}</strong>:
      </Typography>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 4 }}>
        <Grid container spacing={2}>
          {infoComplementares.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{
                padding: 1,
              }}
            >
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                <strong>{item.label}:</strong> {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Speedometer */}
      
      <ReactSpeedometer
        maxValue={5}
        value={speedometerValue}
        needleColor="steelblue"
        startColor="red"
        endColor="green"
        segments={5}
        currentValueText={`Pontuação: ${speedometerValue} / 5`}
        height={200}
      />

      {/* Detalhamento dos Critérios */}
      <Typography variant="h6" sx={{ ...subtituloEstilo }}>
        Detalhamento dos critérios:
      </Typography>
      <Grid container spacing={2}>
        {criteria.map((c, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            sx={{
              backgroundColor:
                Math.floor(index / 3) % 2 === 0 ? '#f9f9f9' : '#ffffff',
              padding: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {c.passed ? (
              <CheckCircleIcon color="success" sx={{ marginRight: 1 }} />
            ) : (
              <CancelIcon color="error" sx={{ marginRight: 1 }} />
            )}
            <Typography variant="body1" sx={{ textAlign: 'left' }}>
              {c.description}
            </Typography>
          </Grid>
        ))}
      </Grid>

     {/* Fórmulas */}
     <Typography variant="h6" sx={{ ...subtituloEstilo, textAlign: 'left' }}>
        Fórmulas:
      </Typography>
      <Grid container spacing={2}>
        {/* Graham */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            backgroundColor: '#f9f9f9',
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {graham.aprovado ? (
            <CheckCircleIcon color="success" sx={{ marginRight: 1 }} />
          ) : (
            <CancelIcon color="error" sx={{ marginRight: 1 }} />
          )}
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            <strong>Graham:</strong> Valor justo: R$ {formatNumber(graham.valor)}
          </Typography>
        </Grid>
        {/* Bazin */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            backgroundColor: '#f9f9f9',
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {bazin.aprovado ? (
            <CheckCircleIcon color="success" sx={{ marginRight: 1 }} />
          ) : (
            <CancelIcon color="error" sx={{ marginRight: 1 }} />
          )}
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            <strong>Bazin:</strong> {bazin.aprovado ? 'Aprovado' : 'Reprovado'}
          </Typography>
        </Grid>
      </Grid>

      {/* Dados Fundamentalistas */}
      <Typography variant="h6" sx={{ ...subtituloEstilo}}>
        Dados Fundamentalistas
      </Typography>
      <Grid container spacing={2}>
        {infoFundamentalistas.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            sx={{
              backgroundColor:
                Math.floor(index / 3) % 2 === 0 ? '#f9f9f9' : '#ffffff',
              padding: 1,
            }}
          >
            <Typography variant="body1" sx={{ textAlign: 'left' }}>
              <strong>{item.label}:</strong> {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {/* Dados Demonstrativos de Resultados */}
      <Typography variant="h6" sx={{ ...subtituloEstilo}}>
        Dados Demonstrativos de Resultados
      </Typography>
      <Grid container spacing={2}>
        {/* Últimos 12 meses */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: 2, fontWeight: 'bold'}}
            >
              Últimos 12 meses
            </Typography>
            {infoDemonstrativo12Meses.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                  padding: 1,
                  textAlign: 'left',
                }}
              >
                <strong>{item.label}:</strong> {item.value}
              </Typography>
            ))}
          </Paper>
        </Grid>
        {/* Últimos 3 meses */}
        <Grid item xs={12} sm={6}>
          <Paper elevation={2} sx={{ padding: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ marginBottom: 2, fontWeight: 'bold'}}
            >
              Últimos 3 meses
            </Typography>
            {infoDemonstrativo3Meses.map((item, index) => (
              <Typography
                variant="body1"
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                  padding: 1,
                  textAlign: 'left',
                }}
              >
                <strong>{item.label}:</strong> {item.value}
              </Typography>
            ))}
          </Paper>
        </Grid>
      </Grid>

      <Typography
        variant="body2"
        sx={{
          fontSize: '12px',
          marginTop: '32px',
          marginBottom: '32px',
          textAlign: 'left',
        }}
      >
        Somente essas informações não podem fazer você escolher se vale a pena investir
        na empresa. É necessário estudar mais sobre ela, mas esses critérios são ótimos
        indicativos.
      </Typography>
    </Box>
  );
};

export default App;
